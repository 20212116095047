.copysmith-editor-wrapper {
  position: relative;
  height: 100%;
}

.copysmith-editor-char-counter {
  position: absolute;
  bottom: 7.5%;
  right: 10%;
  background-color: #f2f2f2;
  height: 24px;
  width: 82px;
  text-align: center;
  border-radius: 3px;
  color: #828282;
  transform: translateY(50%);
}

.copysmith-editor .quill {
  height: 89%;
}

.copysmith-editor .ql-container .ql-editor {
  padding: 50px 70px;
}

.copysmith-editor .ql-container.ql-snow {
  border: none !important;
}
